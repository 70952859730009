<template>
  <form @submit.prevent="createTypeMaterial">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h2>Создание</h2>
      <div>
        <UIButton text="Сохранить" leftIcon="bi-save" type="submit" />
      </div>
    </div>

    <div class="row gap-3">
      <dl class="col">
        <div class="row gap-2">
          <div>
            <dt class="col">Наименование:</dt>
            <dl class="col">
              <EditableInput v-model="data.name" editable required />
            </dl>
          </div>

          <div>
            <dt class="col">Тип затрат:</dt>
            <dl class="col">
              <EditableInput v-model="data.costType" editable required />
            </dl>
          </div>

          <div>
            <dt class="col">Складская позиция:</dt>
            <dl class="col">
              <UISwitch v-model="data.stockItem" editable />
            </dl>
          </div>
        </div>
      </dl>

      <dl class="col">
        <div class="row gap-2">
          <div>
            <dt class="col">Код типа материала:</dt>
            <dl class="col">
              <EditableInput v-model="data.code" editable required />
            </dl>
          </div>

          <div>
            <dt class="col">Единица измерения:</dt>
            <dl class="col">
              <EditableInput v-model="data.quantity" editable required />
            </dl>
          </div>

          <div>
            <dt class="col">Цена за единицу:</dt>
            <dl class="col">
              <EditableInput v-model="data.estUnitCost" editable required />
            </dl>
          </div>

          <div>
            <dt class="col">Статус:</dt>
            <dl class="col">
              <EditableSelect
                v-model="data.statusId"
                :options="status"
                editable
                required
              />
            </dl>
          </div>
        </div>
      </dl>
    </div>
  </form>
</template>

<script>
import UIButton from "@/components/UI/Button";
import EditableInput from "@/components/UI/EditableInput";
import EditableSelect from "@/components/UI/EditableSelect";
import UISwitch from "@/components/UI/Switch";
import {
  API_MultiplierList,
  API_StatusList,
  API_SymbolList,
} from "@/services/api";

import { API_type_material_create } from "@/services/references/typeMaterial";

export default {
  name: "typeMaterialCreate",
  components: { UIButton, EditableInput, EditableSelect, UISwitch },
  data() {
    return {
      data: {
        name: "",
        code: "",
        statusId: null,
        docType: "",
        quantity: "",
        estUnitCost: null,
        stockItem: false,
        multiplierId: null,
        costType: "",
        unitId: null,
      },
      status: [],
      multipliers: [],
      units: [],
    };
  },
  mounted() {
    API_StatusList().then((res) => (this.status = res));
    API_SymbolList().then((res) => (this.units = res));
    API_MultiplierList().then((res) => (this.multipliers = res));
  },
  methods: {
    createTypeMaterial() {
      API_type_material_create(this.data).then((res) => {
        this.$router.push({
          path: `/references/type-material/${res.id}`,
        });
      });
    },
  },
};
</script>

<style scoped></style>
